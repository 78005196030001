<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'AdminPage' }">← Назад</router-link><br><br>
		<section class="grid-wrapper new-rlas">
			<h2 class="heading heading__hr">Новые НПА</h2>
			<ul v-if="getRlas.length > 0">
				<li v-for="rla in getRlas" :key="'rla-item-'+rla.id">
					<router-link :to="{ name: 'AdminMaterialPage', params: { id: rla.id, type: 'rla' } }" class="general-link">
						<kb-card :card-info="rla"/>
					</router-link>
				</li>
			</ul>
		</section>
		<section class="grid-wrapper new-materials">
			<h2 class="heading heading__hr">Новые материалы</h2>
			<ul v-if="getMaterials.length > 0">
				<li v-for="material in getMaterials" :key="'material-item-'+material.id">
					<router-link :to="{ name: 'AdminMaterialPage', params: { id: material.id, type: 'material' } }" class="general-link">
						<kb-card :card-info="material"/>
					</router-link>
				</li>
			</ul>
		</section>
		<section class="grid-wrapper new-qanads">
			<h2 class="heading heading__hr">Новые вопросы\ответы</h2>
			<ul v-if="getQuestions.length > 0">
				<li v-for="question in getQuestions" :key="'question-item-'+question.id">
					<router-link :to="{ name: 'AdminMaterialPage', params: { id: question.id, type: 'faq' } }" class="general-link">
						<kb-card :card-info="question"/>
					</router-link>
				</li>
			</ul>
		</section>
		<section class="new-material-form">
			<button-card
					:to="{ name: 'NewMaterial' }"
					text="Создать заявку на новый материал"
				>
					<template v-slot:icon>
						<icon-checklist/>
					</template>
			</button-card>
		</section>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconChecklist from "../../../assets/svg/IconChecklist.vue"
import KbCard from '../../../components/cards/KbCard.vue'
import ButtonCard from "../../../components/common/ButtonCard.vue"

export default {
	name: 'MaterialsTasks',
	components: { ButtonCard, IconChecklist, KbCard },
	computed: {
		...mapGetters([
			'getMaterials',
			'getRlas',
			'getQuestions'
		])
	},
	methods: {
		...mapActions([
			'fetchUnapproovedMaterials',
			'fetchUnapproovedRlas',
			'fetchUnapproovedQuestions',
		])
	},
	created() {
		this.fetchUnapproovedMaterials()
		this.fetchUnapproovedRlas()
		this.fetchUnapproovedQuestions()
	}
}
</script>

<style scoped>
	.grid-wrapper {
		margin: 30px 0;
	}
	.grid-wrapper ul {
		display: grid;
		grid-auto-flow: dense;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		list-style: none;
		margin: 0; padding: 0;
	}
	.general-link {
		text-decoration: none;
	}
	.general-link * {
		pointer-events: none !important;
	}

	.new-material-form {
		margin-top: 30px;
	}
</style>
